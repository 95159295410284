<template>
  <div>
    <b-container fluid>
      <b-row id="banner" class="image-backdrop" :class="random_colour">
        <b-col
          class="text-center text-light pt-8 pb-8 mx-auto"
          style="background-color: rgba(0, 0, 0, 0.3)"
        >
          <h1 class="mx-auto font-weight-light">My Songs</h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-5 " v-if="songs.length > 0">
      <b-row class="mb-3 mb-md-5" v-for="result in songs" :key="result.id">
        <b-col cols="12" :class="{ 'pr-0': isMobile() }">
          <ArtistAsset
            v-if="result.type === 'artist' && !isMobile()"
            v-bind:artist="result"
          ></ArtistAsset>
          <ArtistAssetMobile
            v-if="result.type === 'artist' && isMobile()"
            v-bind:artist="result"
          ></ArtistAssetMobile>

          <SongAsset
            v-if="result.type === 'song' && !isMobile()"
            v-bind:song="result"
          ></SongAsset>
          <SongAssetMobile
            v-if="result.type === 'song' && isMobile()"
            v-bind:song="result"
          ></SongAssetMobile>

          <PlaylistAsset
            v-if="result.type === 'playlist' && !isMobile()"
            v-bind:playlist="result"
          ></PlaylistAsset>
          <PlaylistAssetMobile
            v-if="result.type === 'playlist' && isMobile()"
            v-bind:playlist="result"
          ></PlaylistAssetMobile>
        </b-col>
      </b-row>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </b-container>

    <b-container class="mt-5" v-if="songs.length === 0 && loaded">
      <p class="text-light font-weight-light text-center py-5">
        No songs yet.
        <router-link class="text-light text-underline" :to="{ name: 'Upload' }"
          >Upload now.</router-link
        >
      </p>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InfiniteLoading from "vue-infinite-loading";
import SongAsset from "../../components/library/list/SongAsset.vue";

export default {
  name: "Songs",
  components: {
    InfiniteLoading,
    SongAsset,
  },
  data() {
    return {
      loaded: false,
      songs: [],
      showByIndex: null,
      next_page: null,
    };
  },
  methods: {
    ...mapActions("Account", {
      songs_action: "songs",
      songs_pagination: "songs_pagination",
      delete_song: "delete_song",
    }),
    infiniteHandler($state) {
      if (this.next_page ) {
      this.songs_pagination(this.next_page).then((res) => {
        this.songs.push(...res.results);
        this.next_page = res.next;
        if (this.next_page) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch((error) => {
        error
            $state.complete();
      });
      } else {
            $state.complete();
      }
    },
    deleteConfirmation(id, song_name) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete - " + song_name + ".",
          {
            title: "Confirm deletion of song",
            size: "md",
            contentClass: "bg-dark text-light font-weight-light",
            titleClass: "font-weight-light",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Keep",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.delete_song(id)
              .then((res) => {
                res;
                this.songs_action()
                  .then((res) => {
                    
                    this.songs = res.results;
                    this.next_page = res.next;
                    
                  })
                  .catch((error) => {
                    error
                    this.$router.replace("/error");
                    
                  });
              })
              .catch((error) => {
                error;
              });
          }
        })
        .catch((err) => {
          // An error occurred
          err;
        });
    },
  },
  computed: {
    ...mapGetters("Generic", { random_colour: "getColour" }),
  },
  mounted() {
    this.songs_action()
      .then((res) => {
        
        this.songs = res.results;
        this.next_page = res.next;
        this.loaded = true;
        
      })
      .catch((error) => {
        error
        this.$router.replace("/error");
        
      });
  },
};
</script>

<style>
.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}

.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.text-underline {
  text-decoration: underline;
}

#banner {
  margin-top: 100px;
}

.border-light-1 {
  border: 1px solid #fff !important;
}

.dropdown-item {
  color: #fff;
}

.dropdown-menu .show {
  border: 1px solid #fff !important;
}

.dropdown-menu a.text-light:hover,
.dropdown-menu a.text-light:focus {
  background-color: #01afdc !important;
  color: black !important;
}

.dropdown-menu a.text-danger:hover,
.dropdown-menu a.text-danger:focus {
  background-color: #e76d66 !important;
  color: black !important;
}
</style>
